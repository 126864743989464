<template>
  <div>
    <TitleMore
      :title="['救援队伍']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard">
      <div style="margin-bottom:20px">
        <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
text-align:center;margin-top:20px;margin-bottom: 10px;">{{firstListData.title}}</p>
        <TitleLine width="200px"></TitleLine>
        <img
          :src="firstListData.smallImage+'!1200x400'"
          style="width:1200px;height: 400px;
          margin:20px 0px;margin-left:-20px;height:400px"
          alt=""
        >
        <p style="
font-size: 16px;
color: #666666;
line-height: 48px;
padding:0px 40px;
padding-bottom: 20px;
border-bottom: 1px solid #f4f4f4;">
          {{firstListData.metaDescription}}
        </p>
      </div>

      <div
        class="itemText"
		style="margin-bottom: 15px;"
        v-for="item in ListData"
        :key="item.id"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <p>
          <img
            src="../../assets/img/team.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
        <p class="itemTextTime">{{item.publishDate}}</p>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      firstListData: [],
      ListData: [],
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getList();
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      apiUrls
        .selfDynamicList({ node: "jydw", page: this.page - 1, size: this.size })
        .then((res) => {
          this.firstListData = res.results.data[0];
          this.ListData = res.results.data;
          this.total = res.results.pager.totalElements;
        });
    },
  },
};
</script>
<style lang="less" scoped>
	.itemTextContent {
		&:hover {
			color: #d1292e;
		}
	}
</style>